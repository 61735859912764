<template>
  <div>
    <el-main>
      <el-form>
        <el-row type="flex" justify="end">
          <el-col :span="2">
            <el-form-item>
              <el-button
                type="primary"
                @click="openDialog('add')"
                v-permission="['admin:role:add']"
                >新增</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 表格 -->
      <el-table border style="width: 60%" :data="tableData">
        <el-table-column type="index" label="#" width="40"></el-table-column>
        <el-table-column label="角色名称" prop="name"></el-table-column>
        <el-table-column label="状态" prop="name">
          <template slot-scope="scope">
           <span>{{scope.row.enabled == "1" ? '已启用' : scope.row.enabled == "0" ? '已禁用' : ''}}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row.id, scope.row.name)"
              v-permission="['admin:role:edit']"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="openButtonControlDialog(scope.row.code, scope.row.name)"
              v-permission="['admin:role:entrust_resource']"
              >设置权限</el-button
            >
             <el-button
              type="primary"
              size="mini"
              @click="stateOperation(scope.row.id, scope.row.enabled,scope.row.name)"
              v-permission="['admin:role:set_enabled']"
              >{{scope.row.enabled == "1" ? '禁用' : '启用'}}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-main>

    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :id="jurisdictionInfo.id"
      :name="jurisdictionInfo.name"
    ></edit>

    <buttonControl
      v-if="buttonControlDialog"
      :editDialog="buttonControlDialog"
      v-on:listenbuttonControlClose="listenbuttonControlClose"
      :code="roleCode"
      :roleName="roleName"
    ></buttonControl>
  </div>
</template>

<script>
import { jurisdictionList, editEnabled } from "@/api/jurisdiction";
import edit from "../components/jurisdiction/edit";
import buttonControl from "../components/jurisdiction/buttonControl";

export default {
  name: "jurisdiction",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      buttonControlDialog: false,
      jurisdictionInfo: {
        id: "",
        name: "",
      },
      typeDialog: "",
      roleCode: null,
      roleName: null,
    };
  },
  components: {
    edit,
    buttonControl,
  },

  mounted() {
    this.queryJurisdiction();
  },

  methods: {
    stateOperation(id,status,name){
      let enabled = status == '1' ? '0' : status == '0' ? '1' : '';
      let enabledText = status == '1' ? '禁用' : '启用';
      this.$confirm("确定要" + enabledText + '角色：' + name + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          editEnabled({id,enabled}).then(
            (response) => {
              if (response.success) { 
                this.$message({
                  type: "success",
                  message: enabledText + "成功!",
                });
              this.queryJurisdiction();
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });

    },
    queryJurisdiction() {
      jurisdictionList().then((response) => {
        this.tableData = response.data;
      });
    },
    openButtonControlDialog(code, name) {
      this.roleCode = code;
      this.roleName = name;
      this.buttonControlDialog = true;
    },
    //新增编辑弹窗显示
    openDialog(type, id, name) {
      console.log(id);
      if (type == "edit") {
        this.typeDialog = "edit";
        this.jurisdictionInfo.id = id;
        this.jurisdictionInfo.name = name;
      } else if (type == "add") {
        this.typeDialog = "add";
      }
      this.editDialog = true;
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.queryJurisdiction();
      }
    },
    //监听编辑弹窗关闭
    listenbuttonControlClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.buttonControlDialog = !isClose;
      if (type == "sure") {
        this.queryJurisdiction();
      }
    },
  },
};
</script>

<style scoped>
</style>
