<template>
  <el-dialog
    class="edit"
    :title="`${roleName}——` + title"
    :visible="editDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
  >
    <!--   -->
    <!-- <div class="bc" v-loading="loading"> -->
    <div class="bc">
      <el-tree
        ref="permitMenuTree"
        :data="permitMenuList"
        show-checkbox
        node-key="id"
        :check-on-click-node="false"
        :check-strictly="false"
        :default-expand-all="false"
        :expand-on-click-node="true"
        :default-checked-keys="permitMenuDefaultCheckedKeys"
        current-node-key="permitMenuCheckedList"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{
            data.type == 1 ? "菜单：" : data.type == 2 ? "按钮：" : ""
          }}</span>
          <span class="name">{{data.name}}</span>
        </span>
      </el-tree>
    </div>
    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import {
  getPermitMenuList,
  editRelation,
  resourceList,
  editRole,
  entrustResource,
} from "@/api/jurisdiction";
import filterListMater from "@/common/filterListMater";
export default {
  name: "buttonControl",
  data() {
    return {
      title: "编辑菜单&按钮权限",
      token: null,
      formData: {},
      checkAll: false,
      checkedList: [],
      isIndeterminate: true,
      resourceData: "",
      resourceDataKeys: "",
      noneList: [],
      noneList2: [],
      hackReset: false,
      selectAllCode: "",
      loading: false,
      permitMenuList: [], //列表
      permitMenuCheckedList: [],
      permitMenuDefaultCheckedKeys: [],
    };
  },
  props: {
    type: String,
    editDialog: Boolean,
    id: String,
    code: String,
    roleName: String,
  },
  components: {},
  created() {},
  mounted() {
    this.getPermitMenuList();
  },
  //keep-alive的生命周期 ——进入组件

  activated() {
    console.log("activated2====");
    //  this.permitMenuList = [];
    // this.permitMenuDefaultCheckedKeys = [];
  },
  deactivated() {
    this.permitMenuList = [];
    this.permitMenuDefaultCheckedKeys = [];
  },
  destroyed() {
    this.permitMenuList = [];
    this.permitMenuDefaultCheckedKeys = [];
  },
  methods: {
    //1
    getPermitMenuList() {
      // this.permitMenuDefaultCheckedKeys = [];
      var that = this;
      that.loading = true;
      that.permitMenuList = [];
      getPermitMenuList(this.code).then((response) => {
        if (response.success) {
          that.permitMenuList = response.data;
          that.filterListMater(that.permitMenuList);
          that.loading = false;
        }
      });
    },
    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenbuttonControlClose", { type: "cancel", isClose: true });
    },

    //确定
    sure() {
      let fullChoosed = this.$refs.permitMenuTree.getCheckedKeys(); // 获取全选节点信息
      let fullChoosed2 = this.$refs.permitMenuTree.getHalfCheckedKeys();
      // console.table(fullChoosed);
      // console.table(fullChoosed2);
      this.formData.roleCode = this.code;
      this.formData.menuIdList = fullChoosed.concat(fullChoosed2);
      this.editRelation();
      // console.log(filterListMater(this.data, "have"));
      // this.permitMenuDefaultCheckedKeys = filterListMater(this.data, "have");
    },
    //递归查找数组中的默认选项
    filterListMater(arr, id) {
      console.log(arr)
      var that = this;
      var result = [];
      if (arr == null || arr.length <= 0) return [];
      arr.forEach(function (item, index) {
        if (item.selected == true) {
          that.permitMenuDefaultCheckedKeys.push(item.id);
          // that.permitMenuDefaultCheckedKeys = [
          //   ...that.permitMenuDefaultCheckedKeys,
          //   // that.collect(item),
          // ];
          // console.log(that.permitMenuDefaultCheckedKeys);
        }
        that.filterListMater(item.children, id);
      });
    },

    editRelation() {
      editRelation(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.$emit("listenbuttonControlClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
  },
  watch: {
    permitMenuList: {
      handler(newValue, oldValue) {
        console.log(this.$refs.permitMenuTree.getCheckedKeys());
        for (let i = 0; i < newValue.length; i++) {
          if (oldValue[i] != newValue[i]) {
            // this.permitMenuDefaultCheckedKeys = filterListMater(
            //   this.permitMenuList
            // );
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.name{
  font-weight: bold;
}
.bc {
  min-height: 32em;
  background-color: #fff;
  .showStatus {
    .one {
      margin: 0 0 30px 0;
      .item1 {
        width: 100%;
        .itemName {
          transform: translateY(18px);
        }
        .itemName1 {
          transform: translateY(18px);
        }
        .item2 {
          transform: translateY(28px);
        }
      }
      .title {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 100px;
  }
}
</style>