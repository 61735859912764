/**
     * @description: 递归遍历查找数组对象的某个值
     * @param {string} code
     * @param {array} arr
     * @returns {array}
     */
var result = [];
console.log(result)
let filterListMater = function (arr) {
    // console.log(result)

    if (arr == null) return null;
    // for (let obj of arr) {
    //     if (obj.have === true) {
    //         result = [...result, collect(obj)];
    //     }
    //     filterListMater(obj.children, id);
    // }
    arr.forEach(function (item, index) {
        if (item.selected === true) {
            // console.log(item.id);

            // result.push(item.id);
            result = [
                ...result,
                collect(item),
            ];
            // console.log(result);
        }
        filterListMater(item.children);
    });
    return result;
}

function collect(obj) {
    let ret = obj;
    if (obj.children) {
        for (let o of obj.children) {
            ret = [...ret, ...collect(o)]
        }
    }
    return ret;
}

export default filterListMater;
